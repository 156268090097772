import s from './style.module.css';
import cn from 'classnames';
import Button from '../Button';
import { useHistory } from 'react-router-dom';

export const StartGameButton = ({ className, isDisabled, disabledClassName, toBoardPage = false }) => {
  const history = useHistory();
  return (
    <Button
      handler={() => history.push(`/game/${toBoardPage ? "board" : ""}`)}
      className={className}
      isDisabled={isDisabled}
      disabledClassName={disabledClassName}
    >
      <span>Start Game</span>
    </Button>
  )
};

const Header = ({ title, desc, startGameButton }) => {
  return (
    <header className={cn(s.root)}>
      <div className={cn(s.forest)}></div>
      <div className={cn(s.silhouette)}></div>
      <div className={cn(s.moon)}></div>
      <div className={cn(s.container)}>
        <h1>{title}</h1>
        <p>{desc}</p>
        {
          startGameButton && <StartGameButton className={cn(s.button)} />
        }
      </div>
    </header>
  );
};

export default Header;
