import s from './style.module.css';
import cn from 'classnames';
import Button from '../../Button';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import SubMenu from "../_subMenu";

const NavBar = ({ bgActive, isOpenMenu, toggleMenuHandler }) => {
  const user = useSelector(state => state.user.data.email);

  return (
    <nav id="navbar" className={cn(s.navbar, bgActive && s.bgActive)}>
      <div className={cn(s.navWrapper)}>
        <Link to="/" className={cn(s.brand)}>
          LOGO
        </Link>
        <div className={cn(s.controls)}>
          {user ? (
            <SubMenu
              className={cn(s.signInButton)}
              text={user}
            />
          ) : (
            <Link
              className={cn(s.signInButton)}
              to="/auth"
            >
              Sign In
            </Link>
          )}
          <Button
            className={cn(s.menuButton, isOpenMenu && s.active)}
            handler={toggleMenuHandler}
          >
            <span />
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
