import s from './style.module.css';
import cn from 'classnames';

const Layout = ({ children, urlBg, colorBg, id, title, className }) => {
  const style = {
    backgroundImage: urlBg && `url(${urlBg})`,
    backgroundColor: colorBg
  };

  return (
    <section style={style} className={cn(s.root, className)} id={id}>
      <article>
        {title && (
          <div className={cn(s.title)}>
            <h3>{title}</h3>
            <span className={cn(s.separator)}></span>
          </div>
        )}
        <div className={cn(s.desc, s.full)}>
          {children}
        </div>
      </article>
    </section>
  );
};

export default Layout;
