import React from "react";
import cn from 'classnames';
import s from './style.module.css';

const Button = React.forwardRef(({ children, type, handler, className, isDisabled = false, disabledClassName }, ref) => {
  return (
    <button
      ref={ref}
      type={type ? type : null}
      className={cn(s.button, className, isDisabled && disabledClassName)}
      onClick={handler}
      disabled={isDisabled}
    >
      { children}
    </button >
  )
});

export default Button;
