import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../Button"
import cn from "classnames"
import s from "./style.module.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { auth } from "../../../configs/firebase";
import { signOut } from "../../../store/pokemons";
import { signOutUser } from "../../../store/user";
import { useDispatch } from "react-redux";

const SubMenuContent = () => {
  let history = useHistory();
  const dispatch = useDispatch()

  const logOut = () => {
    auth.signOut()
      .then(() => (
        dispatch(signOut()),
        dispatch(signOutUser()),
        localStorage.clear()
      ))
      .catch(er => console.log(er.message))
      .finally(() => history.replace("/"));
  };

  return (
    <ul className={cn(s.menu, s.subMenuWrap)}>
      <li>
        <Link to="/game">
          Profile
        </Link>
      </li>
      <li>
        <Button
          className={cn(s.logoutButton)}
          handler={logOut}>
          Logout
        </Button>
      </li>
    </ul>
  )
};

const SubMenu = ({ text, className }) => {
  const [isOpen, SetOpen] = useState(true);
  const buttonRef = useRef(null);

  const ChangeStateSubMenu = useCallback(() => {
    SetOpen(state => !state);
  }, [isOpen]);

  useEffect(() => {
    buttonRef.current.addEventListener("mouseenter", ChangeStateSubMenu);
    buttonRef.current.addEventListener("mouseleave", ChangeStateSubMenu);
    return () => {
      buttonRef.current?.removeEventListener("mouseenter", ChangeStateSubMenu);
      buttonRef.current?.removeEventListener("mouseleave", ChangeStateSubMenu);
    }
  }, [isOpen]);

  return (
    <div style={{ position: "relative" }}>
      <Button className={className} ref={buttonRef}>
        {text}
      </Button>
      {isOpen && (
        // <div className={cn(s.subMenuWrap)}>
        <SubMenuContent />
        // </div>
      )}
    </div>
  )
};

export default SubMenu;