const charCode = (symbol) => String(symbol).charCodeAt();

const detectNeighbors = (position) => {
  let neighborsArray = []; // [left, top, right, bottom ]
  switch (position) {
    case 1:
      neighborsArray = [false, false, 2, 4];
      break;
    case 2:
      neighborsArray = [1, false, 3, 5];
      break;
    case 3:
      neighborsArray = [2, false, false, 6];
      break;
    case 4:
      neighborsArray = [false, 1, 5, 7];
      break;
    case 5:
      neighborsArray = [4, 2, 6, 8];
      break;
    case 6:
      neighborsArray = [5, 3, false, 9];
      break;
    case 7:
      neighborsArray = [false, 4, 8, false];
      break;
    case 8:
      neighborsArray = [7, 5, 9, false];
      break;
    case 9:
      neighborsArray = [8, 6, false, false];
      break;
  }
  return neighborsArray;
};

const neighborCard = (board, position) => board[position - 1]?.card !== null && { ...board[position - 1]?.card };

export const actualBoard = (card, position, board) => {
  board[position - 1] = { ...board[position - 1], card: card } //push card to board

  const neighborsArr = detectNeighbors(position);
  const neighborsCards = neighborsArr.map(position => position ? neighborCard(board, position) : false);

  neighborsCards.map((item, index) => {
    if (item) {
      switch (index) {
        case 0: //left
          if (charCode(card.values.left) - charCode(item.values.right) > 0) board[position - 2].card = { ...item, possession: card.possession }
          break;
        case 1: //top
          if (charCode(card.values.top) - charCode(item.values.bottom) > 0) board[position - 4].card = { ...item, possession: card.possession }
          break;
        case 2: //right
          if (charCode(card.values.right) - charCode(item.values.left) > 0) board[position].card = { ...item, possession: card.possession }
          break;
        case 3: //bottom
          if (charCode(card.values.bottom) - charCode(item.values.top) > 0) board[position + 2].card = { ...item, possession: card.possession }
          break;
      }
    }
  });

  return board;
};