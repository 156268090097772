import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from './_menu'
import NavBar from './_navBar'

const MenuHeader = ({ bgActive }) => {
  const [isOpenMenu, setOpenMenu] = useState(null);
  const { pathname } = useHistory().location;

  useEffect(() => {
    isOpenMenu && setOpenMenu(false);
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <NavBar
        isOpenMenu={isOpenMenu}
        bgActive={bgActive}
        toggleMenuHandler={() => setOpenMenu(prevState => !prevState)}
      />
      <Menu isOpenMenu={isOpenMenu} />
    </>
  )
}

export default MenuHeader
