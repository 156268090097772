import s from "./style.module.css";
import cn from "classnames";
import GoogleIcon from "../../../assets/images/google.svg";
import Button from "../../Button"
import { createProfile } from "../../Form/_registration";
import { auth, googleProvider } from "../../../configs/firebase";
import { setLoadUser, setErrorUser } from "../../../store/user";
import { useDispatch } from "react-redux";

const withGoogleRegistrationHandler = (dispatch) => {
  dispatch(setLoadUser());
  auth.signInWithPopup(googleProvider)
    .then((result) => createProfile(result.user, dispatch))
    .catch(er => dispatch(setErrorUser(er.code)));
};

const RegistrationButton = ({ img, alt, handler }) => {
  return (
    <Button
      type="button"
      className={cn(s.button)}
      handler={handler}
    >
      <img src={img} alt={alt} />
    </Button>
  )
}

const RegistrationVariantButtonsBlock = () => {
  const dispatch = useDispatch();
  return (
    <>
      <RegistrationButton
        img={GoogleIcon}
        alt="Google icon"
        handler={() => withGoogleRegistrationHandler(dispatch)}
      />
    </>
  )
};

export default RegistrationVariantButtonsBlock;