import s from "./style.module.css";
import cn from "classnames";
import InputField from "../Controls/_inputField";
import RegistrationVariantButtonsBlock from "../Button/_registers";
import Button from "../Button";
import { useSelector } from "react-redux"

import { Formik, Field } from 'formik';

const MainForm = ({ inputClassName, auth = false, initialValues, onSubmit, errorText }) => {
  const { isLoading } = useSelector(state => state.user)
  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors = {};
        Object.keys(values).map(item => {
          if (!values[item]) { errors[item] = 'Required' }
        });
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (auth && values.password !== values.confirm) { errors.confirm = "password not confirm" }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={cn(s.wrap, s.flex)}>
          <fieldset className={cn(s.form, s.flex)}>
            <InputField
              type="email"
              name="email"
              required
              placeholder="example@mail.com"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={cn(inputClassName, errors.email && s.error)}
              additionalField={errors.email && touched.email && (
                <span className={cn(s.error, s["error-message"])}>
                  {errors.email}
                </span>
              )}
            />
            <InputField
              type="password"
              name="password"
              required
              placeholder="************"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={cn(inputClassName, errors.password && s.error)}
              additionalField={errors.password && touched.password && (
                <span className={cn(s.error, s["error-message"])}>
                  {errors.password}
                </span>
              )}
            />
            {auth ? (
              <>
                <InputField
                  label="confirm password"
                  type="password"
                  name="confirm"
                  required
                  placeholder="************"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm}
                  className={cn(inputClassName, errors.confirm && s.error)}
                  additionalField={errors.confirm && touched.confirm && (
                    <span className={cn(s.error, s["error-message"])}>
                      {errors.confirm}
                    </span>
                  )}
                />
                <label className={cn(s["checkBox-label"])}>
                  <Field
                    type="checkbox"
                    className={cn(s["checkBox-position"], s.checkBox)}
                    name="privacy"
                  />
                  <span className={cn(s["label-text"], s["required-field"], s.privacy)}>I`m agree a Privacy Policy</span>
                  {errors.privacy && touched.privacy && (
                    <span className={cn(s.error, s["error-message"])}>
                      {errors.privacy}
                    </span>
                  )}
                </label>
              </>
            ) : null}
          </fieldset>
          <div className={cn(s.footer)}>
            <Button
              type="submit"
              isDisabled={isLoading}
              className={cn(s.submit)}
              disabledClassName={cn(s.submit, s.disabled)}
            >
              Send
          </Button>
            <div className={cn(s.additional_block)}>
              {auth ? <RegistrationVariantButtonsBlock /> : (
                errorText && (
                  <span className={cn(s.error, s.footer_error)}>
                    {errorText}
                  </span>
                ))
              }
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default MainForm;