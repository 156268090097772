import firebase from 'firebase/app';
import 'firebase/database';
import { sdkFirebase } from "./"

export class FetchPokemons {
  constructor() {
    firebase.apps.length === 0 && sdkFirebase();

    this.fire = firebase;
    this.database = this.fire.database();
    this.base = process.env.REACT_APP_FIREBASE_BASE_NAME;
  }

  getFetchOnce = async (user) => {
    return await this.database.ref(this.base + "/" + user).once('value').then(snapShot => snapShot.val());
  };

  postFetch = (key, pokemon) => {
    let id = key;
    if (!id) {
      id = this.database.ref(this.base).push();
    };
    this.database.ref(`${this.base}/${id}`).set(pokemon)
  };

  updateFetch = async (key, pokemon) => {
    return await this.database.ref(`${this.base}/${key}/pokemons`).update({ [pokemon.id]: pokemon })
  }
};