import PokemonCard from '../PokemonCard';

const PokemonCards = ({ pokemons, handlerClick, className, minimize, isSelected, isActive }) => {
  return Object.values(pokemons).map((pokemon, index) => {
    const id = pokemon.id;
    return (
      <PokemonCard
        key={id}
        id={id}
        name={pokemon.name}
        type={pokemon.type}
        values={pokemon.values}
        img={pokemon.img}
        handlerClick={handlerClick && handlerClick(pokemon)}
        isActive={pokemon.active || isActive}
        isSelected={pokemon.selected}
        className={className}
        minimize={minimize}
      />
      // </div>
    )
  })
};

export default PokemonCards;