import { createSlice } from '@reduxjs/toolkit';

const pokemons = (state, action, player) => ({
  ...state,
  [player]: {
    ...state[player],
    [action.payload.id]: {
      ...action.payload
    }
  }
})

export const slice = createSlice({
  name: "gamePokemons",
  initialState: {
    player1Pokemons: {},
    player2Pokemons: {}
  },
  reducers: {
    setLoadPokemon: state => ({
      ...state,
      isLoading: true,
    }),

    fetchPokemonsPlayer1: (state, action) => {
      if (state.player1Pokemons[action.payload.id]) {
        const copyState = { ...state.player1Pokemons };
        delete copyState[action.payload.id];
        return {
          ...state,
          player1Pokemons: {
            ...copyState
          }
        }
      };
      return pokemons(state, action, 'player1Pokemons')
    },
    fetchPokemonsPlayer2: (state, action) => ({
      ...state,
      player2Pokemons: {
        ...action.payload
      }
    }),
    resetPokemonsPlayers: () => ({
      player1Pokemons: {},
      player2Pokemons: {}
    }),
    setResolvePokemon: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),
    setReject: (state, action) => ({
      ...state,
      isLoading: false,
      data: {},
      error: action.payload,
    }),
  },
});

export const { fetchPokemonsPlayer1, fetchPokemonsPlayer2, resetPokemonsPlayers, setLoadPokemon, setResolvePokemon, setReject } = slice.actions;
export const selectGamePokemonsData = state => state.gamePokemons;

export const endGameClear = () => async (dispatch) => {
  dispatch(resetPokemonsPlayers());
};

export const setPlayer1Pokemons = (pokemon) => async (dispatch) => {
  dispatch(fetchPokemonsPlayer1(pokemon));
}

export const setPlayer2Pokemons = () => async (dispatch) => {
  dispatch(setLoadPokemon);
  try {
    const data = await fetch('https://reactmarathon-api.netlify.app/api/create-player')
      .then(request => request.json());
    dispatch(fetchPokemonsPlayer2(data.data));
  }
  catch (er) {
    dispatch(setReject(er));
  }
  finally {
    dispatch(setResolvePokemon());
  }
};

export default slice.reducer;
