import s from './style.module.css';
import cn from 'classnames';
import { GamePageContext } from '../../../../context';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import PokemonCard from '../../../../components/Cards/PokemonCard';
import PokemonCards from '../../../../components/Cards/PokemonCards';
import Result from '../../../../components/Result';
import ArrowChoice from '../../../../components/ArrowChoice';
import { player1, player2, gameBoard } from "../../../../assets/Constants/constants"
import { selectGamePokemonsData, setPlayer2Pokemons } from "../../../../store/gamePokemons"

import { actualBoard } from "../../../../components/Board";

const PlayerCards = ({ pokemons, pokemonClick, player }) => {
  return (
    <PokemonCards
      pokemons={pokemons}
      handlerClick={(pokemon) => pokemonClick(pokemon, player)}
      isActive
      className={cn(s.card)}
      minimize
    />
  );
};

const countPlayerPoints = (board, player1, player2) => {
  let count1 = 0;
  let count2 = 0;
  board.map(item => item.card?.possession === "blue" ? count1++ : count2++);
  return [{ value: count1 + player1 }, { value: count2 + player2 }]
};

const detectFirstPlayer = () => new Promise((resolve, reject) => {
  setTimeout(function () {
    var didSucceed = Math.floor(Math.random() * 100);
    didSucceed ? resolve(didSucceed) : reject('Error');
  }, 4000);
});

const setPlayerCard = (player, color) => Object.values(player).map(item => ({
  ...item,
  possession: color,
}));

const BoardPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { player1Pokemons, player2Pokemons } = useSelector(selectGamePokemonsData);
  const gameContext = useContext(GamePageContext);

  const pokemons = Object.values(player1Pokemons);

  const [player1Cards, setPlayer1Cards] = useState(() => setPlayerCard(player1Pokemons, "blue"));
  const [player2Cards, setPlayer2Cards] = useState();

  const [isChooseCard, setChooseCard] = useState(null);
  const [board, setBoard] = useState(gameBoard);
  const [result, setResult] = useState(null);
  const [firstStep, setFirstStep] = useState(0);
  const [countStep, setCountStep] = useState(0);
  const [activePlayer, setActivePlayer] = useState(0);
  const [startGame, setStartGame] = useState(false)

  pokemons.length === 0 && history.replace('/game');

  //------------------ detect first player -------------------
  const firstPlayerStart = useCallback((num) => {
    setFirstStep(num);
    setActivePlayer(num);
  }, []);

  useEffect(() => {
    detectFirstPlayer()
      .then(currentTime => firstPlayerStart(currentTime % 2 === 0 ? player2 : player1))
      .catch(err => console.log('There was an error:' + err));
  }, [firstPlayerStart]);

  //------------------ getDataPlayer2Cards -------------------
  useEffect(() => {
    dispatch(setPlayer2Pokemons(player2Cards));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPlayer2Cards(setPlayerCard(player2Pokemons, "red"));
  }, [player2Pokemons]);

  const pokemonClick = (pokemon, player) => {
    return () => {
      pokemon.selected = !pokemon.selected;
      return (
        setChooseCard({ ...pokemon, player: player }),
        setStartGame(true)
      );
    }
  };

  const moveCardToField = async (position) => {
    if (isChooseCard) {
      const actBoard = await actualBoard(isChooseCard, position, board);
      setBoard(actBoard);
      setChooseCard(null);
    };

    if (isChooseCard.player === 1) setPlayer1Cards(prevState => {
      return prevState.filter(item => item.id !== isChooseCard.id)
    })
    else if (isChooseCard.player === 2) setPlayer2Cards(prevState => {
      return prevState.filter(item => item.id !== isChooseCard.id)
    });

    setCountStep(prevCount => prevCount + 1);
  };

  const detectWinner = (player1FinishCards, player2FinishCards) => {
    const [player1count, player2count] = countPlayerPoints(board, player1FinishCards, player2FinishCards);
    if (player1count.value > player2count.value) setResult("win");
    else if (player1count.value < player2count.value) setResult("lose");
    else setResult("draw");
  };

  //------------------ move to finish page after end game ----------
  useEffect(() => {
    gameContext.setWinner(result);
    result && setTimeout(() => {
      history.push("/game/finish")
    }, 4000);
    // eslint-disable-next-line
  }, [result]);

  const stopGame = countStep === 9;

  //------------------ toggle step and active player ---------------
  useEffect(() => {
    const player1FinishCards = player1Cards.length;
    const player2FinishCards = player2Cards?.length;
    startGame && ((countStep + firstStep) % 2 === 0 ? setActivePlayer(player2) : setActivePlayer(player1));
    stopGame && detectWinner(player1FinishCards, player2FinishCards);
    // eslint-disable-next-line
  }, [countStep, firstStep, startGame]);

  return (
    <div className={cn(s.root, { [s.chooseStartPlayer]: !startGame })}>
      {!startGame && <ArrowChoice side={firstStep} />}
      <div className={cn(s.player, s.one, { [s.active]: activePlayer === player1 && !stopGame })}>
        <PlayerCards pokemons={player1Cards} player={1} pokemonClick={activePlayer === player1 ? pokemonClick : () => { }} />
      </div>
      <div className={cn(s.board)}>
        {board?.map(({ position, card }) => {
          return (
            <div
              key={position}
              className={cn(s.boardPlate)}
              onClick={isChooseCard ? () => !card && moveCardToField(position) : () => { }}
            >
              {card && <PokemonCard {...card} minimize isActive className={cn(s.card, s.player)} />}
            </div>
          )
        })}
      </div>
      <div className={cn(s.player, s.two, { [s.active]: activePlayer === player2 && !stopGame })}>
        {player2Cards && <PlayerCards pokemons={player2Cards} player={2} pokemonClick={activePlayer === player2 ? pokemonClick : () => { }} />}
      </div>
      {result && <Result type={result} />}
    </div >
  );
};

export default BoardPage;