export const menu = [
  {
    title: 'HOME',
    to: '/',
  },
  {
    title: 'GAME',
    to: '/game',
  },
  {
    title: 'ABOUT',
    to: '/about',
  },
  {
    title: 'CONTACT',
    to: '/contacts',
  },
];

export const authorizeControls = ["Sign In", "Register"];

export const userData = (user) => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
});

export const gameBoard = [
  {
    "position": 1,
    "card": null
  },
  {
    "position": 2,
    "card": null
  },
  {
    "position": 3,
    "card": null
  },
  {
    "position": 4,
    "card": null
  },
  {
    "position": 5,
    "card": null
  },
  {
    "position": 6,
    "card": null
  },
  {
    "position": 7,
    "card": null
  },
  {
    "position": 8,
    "card": null
  },
  {
    "position": 9,
    "card": null
  }
];

export const fullScreenPages = {
  main: '/',
  board: "/game/board",
};

export const maxCountPokemons = 5;

export const player1 = 1;
export const player2 = 2;