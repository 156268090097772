import cn from 'classnames';
import s from './style.module.css'
import CardBackedPicture from "../../../assets/images/card-back-side.jpg"

const PokemonCard = ({
	id,
	name,
	type,
	values,
	img,
	handlerClick,
	isSelected,
	isActive = false,
	minimize = false,
	className,
	possession
}) => {
	return (
		<div
			id={id}
			style={{ boxShadow: isSelected && `0 5px 10px -5px rgba(0,0,0,0.5), 0 10px 30px 5px var(--${type})` }}
			className={cn(
				className,
				s.pokemonCard,
				{ [s.active]: isActive, [s.selected]: isSelected, [s.minimize]: minimize }
			)} onClick={handlerClick}>
			<div className={cn(s.cardFront)}>
				<div className={cn(s.wrap, s.front)}>
					<div className={cn(s.pokemon, s[type], s[possession])}>
						<div className={cn(s.values)}>
							<div className={cn(s.count, s.top)}>{values.top}</div>
							<div className={cn(s.count, s.right)}>{values.right}</div>
							<div className={cn(s.count, s.bottom)}>{values.bottom}</div>
							<div className={cn(s.count, s.left)}>{values.left}</div>
						</div>
						<div className={cn(s.imgContainer)}>
							<img src={img} alt={`${name} pokemons`} />
						</div>
						{!minimize && (
							<div className={cn(s.info)}>
								<span className={cn(s.number)}>#{id}</span>
								<h3 className={cn(s.name)}>{name}</h3>
								<small className={cn(s.type)}>Type: <span>{type}</span></small>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={cn(s.cardBack)}>
				<div className={cn(s.wrap, s.back)}>
					<img src={CardBackedPicture} alt="Сard Backed" />
				</div>
			</div>
		</div>
	);
};

export default PokemonCard;
