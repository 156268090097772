import s from './style.module.css';
import cn from 'classnames';
import Button from "../../components/Button";
import {useHistory} from 'react-router-dom';


const ContactsPage = ({onChangePage}) => {
  const history = useHistory();
  return (
    <div>
      <p>This is Contacts Page</p>
      <Button 
        handler={() => history.push('/')}
        className={cn(s.button)}
      >
        Home
      </Button>
    </div>
  )
};

export default ContactsPage;
