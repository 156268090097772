import { useState, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import cn from 'classnames';
import s from './style.module.css';
import PokemonCards from "../../../../components/Cards/PokemonCards";
import Layout from '../../../../components/Layout';
import { GamePageContext } from '../../../../context';
import Button from '../../../../components/Button';
import { useHistory } from 'react-router-dom';
import { selectGamePokemonsData, endGameClear, setReject } from "../../../../store/gamePokemons"
import { FetchPokemons } from "../../../../configs/firebase/pokemons.js";

const FinishPage = () => {
  const history = useHistory();
  const { player1Pokemons, player2Pokemons } = useSelector(selectGamePokemonsData);
  const gameContext = useContext(GamePageContext);
  const [winCard, setWinCard] = useState(null);
  const dispatch = useDispatch();
  const { uid } = useSelector(state => state.user.data);

  const onClick = (pokemon) => {
    return () => {
      return setWinCard({ ...pokemon, selected: !pokemon.selected });
    }
  };

  const endGame = async () => {
    winCard && delete winCard.selected;
    return (
      winCard && await new FetchPokemons().updateFetch(uid, winCard)
        .then(() => dispatch(endGameClear()))
        .catch(er => dispatch(setReject(er.message)))
        .finally(history.replace("/game"))
    )
  };

  return (
    <Layout
      title="Finish Page"
      className={cn(s.wrap)
      }
    >
      <div className={cn(s.playerCards)}>
        <PokemonCards
          pokemons={Object.values(player1Pokemons)}
          className={cn(s.card)}
          isActive
        />
      </div>
      <div className={cn(s.playerCards)}>
        <Button handler={endGame} className={cn(s.button)}>
          End Game
          </Button>
      </div>
      <div className={cn(s.playerCards)}>
        <PokemonCards
          pokemons={Object.values(player2Pokemons)}
          className={cn(s.card)}
          isActive
          handlerClick={gameContext.winner === "win" ? onClick : () => { }}
        />
      </div>
    </Layout >
  )
};

export default FinishPage;
