import firebase from 'firebase/app';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDRxOWJzjp5_unOpdAAUAkSqIsLKRw4IyU",
  authDomain: "pokemon-96023.firebaseapp.com",
  projectId: "pokemon-96023",
  storageBucket: "pokemon-96023.appspot.com",
  messagingSenderId: "699295890728",
  appId: "1:699295890728:web:65ad33ebe83aeb5e4da41d"
};

export const sdkFirebase = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
