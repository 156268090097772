import { auth } from "../../../configs/firebase";
import MainForm from "../";
import { useDispatch, useSelector } from "react-redux";
import { setLoadUser, setErrorUser } from "../../../store/user";
import { useCallback } from "react";


const IdentificationForm = ({ inputClassName }) => {
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.user)

  const onSubmit = useCallback((values) => {
    dispatch(setLoadUser());
    auth.signInWithEmailAndPassword(values.email, values.password)
      .then(() => localStorage.setItem('activeUser', values.email))
      .catch(er => dispatch(setErrorUser(er.code)));
  }, [dispatch]);

  return (
    <MainForm
      initialValues={{
        email: '', password: ''
      }}
      inputClassName={inputClassName}
      onSubmit={onSubmit}
      errorText={error}
    />
  );
};

export default IdentificationForm;