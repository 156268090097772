import cn from 'classnames';
import s from './style.module.css';
import {menu} from '../../../assets/Constants/constants'
import {Link} from 'react-router-dom';

const Menu = ({isOpenMenu}) => {
  return (
    <div className={cn(s.menuContainer, {
      [s.active]: isOpenMenu === true,
      [s.deactive]: isOpenMenu === false,
    })}>
      <div className={cn(s.overlay)} />
      <div className={cn(s.menuItems)} >
        <ul>
          {menu.map(item => (
            <li key={item.title}>
              <Link to={item.to}>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
