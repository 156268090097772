import s from "../../Form/style.module.css";
import cn from "classnames";

const InputField = ({ children, type, placeholder, auth, className, label, onChange, value, onBlur, name, additionalField, required }) => {
  return (
    <div className={cn(s.fieldWrap, additionalField && s.fieldWrapWithAdditional)}>
      <label className={cn(s.label)}>
        <span className={cn(s["label-text"], required && s.["required-field"])}>
          {label ? label : type}
        </span>
        <input
          id={label}
          type={type}
          name={name}
          placeholder={placeholder}
          className={cn(s.field, className)}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
        >
          {children && children}
        </input>
      </label>
      {additionalField}
    </div>
  )
};

export default InputField;