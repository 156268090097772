import { auth } from "../../../configs/firebase";
import MainForm from "..";
import { FetchPokemons } from "../../../configs/firebase/pokemons";
import { pokemons } from "../../../assets/Constants/pokemons.json";
import { useDispatch, useSelector } from "react-redux";
import { setLoadUser, setErrorUser } from "../../../store/user";
import { setReject } from "../../../store/pokemons";
import { useCallback } from "react";

export const createProfile = (user, dispatch) => {
  new FetchPokemons().postFetch(user.uid, { email: user.email, pokemons: pokemons })
    .catch((er) => dispatch(setReject(er.message)));
};

const RegistrationForm = ({ inputClassName }) => {
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.user);

  const onSubmit = useCallback((values) => {
    dispatch(setLoadUser());
    auth.createUserWithEmailAndPassword(values.email, values.password)
      .then((result) => createProfile(result.user, dispatch))
      .catch(er => dispatch(setErrorUser(er.code)));
  }, [dispatch]);

  return (
    <MainForm
      auth
      initialValues={{
        email: '', password: '', confirm: '', privacy: false
      }}
      inputClassName={inputClassName}
      onSubmit={onSubmit}
      errorText={error}
    />
  );
};

export default RegistrationForm;