import s from "./style.module.css";
import cn from "classnames";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import { useCallback, useState } from "react";
import { authorizeControls } from "../../assets/Constants/constants";
import RegistrationForm from "../../components/Form/_registration";
import IdentificationForm from "../../components/Form/_identification";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";

const SignButtons = ({ activeClassName, setPanelPositionOnSignIn }) => {
  const [activeIndexButton, setActiveIndexButton] = useState(0);

  const handler = useCallback((index, value) => (
    setActiveIndexButton(index),
    setPanelPositionOnSignIn(value)
  ), [setPanelPositionOnSignIn]);

  return authorizeControls.map((value, index) => (
    <Button
      className={cn(s.container, s["button-wrap"], activeIndexButton === index && s.active)}
      key={value}
      handler={() => handler(index, value)}
    >
      {value}
    </Button>
  ))
}

const AuthPage = () => {
  const user = useSelector(state => state.user.data.email);
  const [panelPositionOnSignIn, setPanelPositionOnSignIn] = useState(authorizeControls[0]);
  const isSignIn = panelPositionOnSignIn !== authorizeControls[0];
  return (
    <Layout>
      <div className={cn(s.wrap, s["title-wrap"])}>
        <SignButtons
          setPanelPositionOnSignIn={setPanelPositionOnSignIn}
        />
      </div>
      <div className={cn(s.wrap, s["auth-wrap"])}>
        <div className={cn(s.toggleWrapPanel, isSignIn && s.right)} />
        <div className={cn(s.container)}>
          <IdentificationForm
            inputClassName={cn(s.input)}
          />
        </div>
        <div className={cn(s.container)}>
          {isSignIn && <RegistrationForm inputClassName={cn(s.input)} />}
        </div>
      </div>
      {!!user && (
        <Redirect to="/game" />
      )}
    </Layout>
  )
};

export default AuthPage;