import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import cn from 'classnames';
import s from './style.module.css';

import PokemonCards from "../../../../components/Cards/PokemonCards";
import Layout from '../../../../components/Layout'
import { StartGameButton } from '../../../../components/Header';
import BgImage_2 from "../../../../assets/images/bg3.jpg";
import { setPlayer1Pokemons, selectGamePokemonsData } from '../../../../store/gamePokemons';
import { getFetchAsync } from '../../../../store/pokemons';
import { maxCountPokemons } from '../../../../assets/Constants/constants';

const StartPage = ({ onChangePage }) => {
  const [allPokemons, setAllPokemons] = useState({});
  const pokemons = useSelector(state => state.pokemons.data);
  const { uid } = useSelector(state => state.user.data);

  const dispatch = useDispatch();
  const { player1Pokemons } = useSelector(selectGamePokemonsData);

  useEffect(() => {
    dispatch(getFetchAsync(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    pokemons && setAllPokemons(pokemons);
  }, [pokemons]);

  const stopChoosePokemons = Object.keys(player1Pokemons).length < maxCountPokemons;

  const onClickPokemon = (pokemon) => {
    if (stopChoosePokemons || player1Pokemons[pokemon.id]) {
      return () => {
        return (
          dispatch(setPlayer1Pokemons(pokemon)),
          setAllPokemons(prevState => ({
            ...prevState,
            [pokemon.id]: {
              ...prevState[pokemon.id],
              selected: !pokemon.selected
            }
          }))
        );
      }
    }
  };

  return (
    <Layout
      title="Your cards"
      className={cn(s.wrap)}
      urlBg={BgImage_2}
    >
      <span className={cn(s.description)}>
        For start game, you can choose 5 pokemon, not more.
      </span>
      <div className={cn(s.buttonContainer)}>
        <StartGameButton
          isDisabled={stopChoosePokemons}
          className={cn(s.addPokemonButton)}
          disabledClassName={cn(s.disabled)}
          toBoardPage
        />
      </div>
      <div className={cn(s.flex)}>
        <PokemonCards
          pokemons={allPokemons}
          handlerClick={onClickPokemon}
          isActive
          className={cn(s.card)}
        />
      </div>
    </Layout >
  )
};

export default StartPage;
