import HomePage from './routes/Home';
import GamePage from './routes/Game/routes';
import AboutPage from './routes/About';
import ContactsPage from './routes/Contacts';
import AuthPage from './routes/Authorization';
import Page404 from './routes/404';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import MenuHeader from './components/MenuHeader';
import Footer from './components/Footer';
import s from './app.module.css';
import cn from 'classnames';
import { useDispatch } from "react-redux";
import { auth } from "./configs/firebase";
import { setCurrentUser, setLoadUser, setRejectUser } from "./store/user";
import { userData, fullScreenPages } from "./assets/Constants/constants";
import { useEffect } from 'react';

function App() {
  const user = localStorage.getItem('activeUser');
  const location = useLocation();
  const isFullScreen = Object.values(fullScreenPages).find(page => location.pathname === page);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoadUser());
    try {
      auth.onAuthStateChanged(user => dispatch(setCurrentUser(auth.currentUser ? userData(user) : {})));
    }
    catch (er) {
      dispatch(setRejectUser(er))
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/404">
        <Page404 />
      </Route>
      <Route>
        <MenuHeader bgActive={!isFullScreen} />
        <div className={cn(!isFullScreen && s.wrap)}>
          <Switch>
            <Route exact path='/'>
              <HomePage />
            </Route>
            <Route exact path='/auth'>
              <AuthPage />
            </Route>
            <Route path='/game'>
              {!!user ? <GamePage /> : <AuthPage />}
            </Route>
            <Route path='/about'>
              <AboutPage />
            </Route>
            <Route path='/contacts' >
              <ContactsPage />
            </Route>
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </div>
        {location.pathname !== fullScreenPages.board && <Footer />}
      </Route>
    </Switch>
  )
};

export default App;
