import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    data: {},
    error: null
  },
  reducers: {
    setLoadUser: state => ({
      ...state,
      isLoading: true,
    }),
    setResolveUser: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),
    setRejectUser: (state, action) => ({
      ...state,
      isLoading: false,
      data: {},
      error: action.payload,
    }),

  }
});

export const { setLoadUser, setResolveUser, setRejectUser } = slice.actions;

export const selectUsersLoading = state => state.user.isLoading;
export const selectUsersData = state => state.user.data;

export const setCurrentUser = (user) => async (dispatch) => {
  dispatch(setResolveUser(user));
};
export const signOutUser = () => async (dispatch) => {
  dispatch(setResolveUser({}));
}
export const setErrorUser = (er) => dispatch => {
  dispatch(setRejectUser(er))
}

export default slice.reducer;