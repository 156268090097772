import { Switch, Route, useRouteMatch } from 'react-router-dom';
import StartPage from './Start'
import BoardPage from './Board'
import FinishPage from './Finish'
import { GamePageContext } from '../../../context'
import { useState } from 'react';


const GamePage = () => {
  const match = useRouteMatch();
  const [winner, setWinner] = useState(null);

  return (
    <GamePageContext.Provider value={{
      winner,
      setWinner
    }}>
      <Switch>
        <Route path={`${match.path}/`} exact component={StartPage} />
        <Route path={`${match.path}/board`} component={BoardPage} />
        <Route path={`${match.path}/finish`} component={FinishPage} />
      </Switch>
    </GamePageContext.Provider>
  );
};

export default GamePage;