import { configureStore } from "@reduxjs/toolkit";
import pokemonReducer from "./pokemons";
import gamePokemonReducer from "./gamePokemons";
import userReducer from "./user";

export default configureStore({
  reducer: {
    pokemons: pokemonReducer,
    gamePokemons: gamePokemonReducer,
    user: userReducer
  }
})