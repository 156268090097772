import s from './style.module.css';
import cn from 'classnames';
import Button from "../../components/Button";
import { useHistory } from 'react-router-dom';
import MenuHeader from '../../components/MenuHeader';
import Footer from '../../components/Footer';

const Page404 = () => {
  const history = useHistory();
  return (
    <>
      <MenuHeader bgActive />
      <div className={cn(s.wrap)}>
        <h1>This is 404 page</h1>
        <Button
          handler={() => history.push('/')}
          className={cn(s.button)}
        >
          Home
        </Button>
      </div>
      <Footer />
    </>
  )
};

export default Page404;
