import { createSlice } from '@reduxjs/toolkit';
import { FetchPokemons } from '../configs/firebase/pokemons';

export const slice = createSlice({
  name: "pokemons",
  initialState: {
    isLoading: false,
    data: {},
    error: null
  },
  reducers: {
    setLoadPokemons: state => ({
      ...state,
      isLoading: true,
    }),
    fetchPokemons: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),
    setReject: (state, action) => ({
      ...state,
      isLoading: false,
      data: {},
      error: action.payload,
    }),
  }
});

export const { setLoadPokemons, fetchPokemons, setReject } = slice.actions;

export const selectPokemonsLoading = state => state.pokemons.isLoading;

export const getFetchAsync = (user) => async (dispatch) => {
  dispatch(setLoadPokemons());
  new FetchPokemons().getFetchOnce(user)
    .then(res => dispatch(fetchPokemons(res.pokemons)))
    .catch((er) => dispatch(setReject(er.message)))
};

export const signOut = () => async (dispatch) => {
  dispatch(fetchPokemons({}));
}

export default slice.reducer;